var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',[_c('h2',[_c('feather-icon',{attrs:{"icon":"LayersIcon"}}),_vm._v(" "+_vm._s(_vm.$t('AppTI.filters.provider'))+" ")],1)]),_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.columns.name'),"rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('h4',[_vm._v(_vm._s(_vm.$t('AppTI.columns.name')))]),_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('AppTI.columns.name')},model:{value:(_vm.providerData.nombre),callback:function ($$v) {_vm.$set(_vm.providerData, "nombre", $$v)},expression:"providerData.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.provider.alias'),"rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('h4',[_vm._v(_vm._s(_vm.$t('AppTI.provider.alias')))]),_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('AppTI.provider.alias')},model:{value:(_vm.providerData.alias),callback:function ($$v) {_vm.$set(_vm.providerData, "alias", $$v)},expression:"providerData.alias"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.provider.rfc'),"rules":"required|max:13|min:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('h4',[_vm._v(_vm._s(_vm.$t('AppTI.provider.rfc')))]),_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('AppTI.provider.rfc'),"onkeyup":"this.value = this.value.toUpperCase();"},model:{value:(_vm.providerData.rfc),callback:function ($$v) {_vm.$set(_vm.providerData, "rfc", $$v)},expression:"providerData.rfc"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.provider.address'),"rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('h4',[_vm._v(_vm._s(_vm.$t('AppTI.provider.address')))]),_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('AppTI.provider.address')},model:{value:(_vm.providerData.domicilio),callback:function ($$v) {_vm.$set(_vm.providerData, "domicilio", $$v)},expression:"providerData.domicilio"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.columns.email'),"rules":"required|email|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('h4',[_vm._v(_vm._s(_vm.$t('AppTI.columns.email')))]),_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('AppTI.columns.email')},model:{value:(_vm.providerData.correo),callback:function ($$v) {_vm.$set(_vm.providerData, "correo", $$v)},expression:"providerData.correo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"float-right mt-2 mb-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[(_vm.addingTicket)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('Lists.Add'))+" ")],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }